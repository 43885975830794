import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import { BlogCardDeckStyles } from '../../styles/Components/BlogCardDeck/BlogCardDeckStyles';
import BlogCard from './BlogCard';

const BlogCardDeck = ({ recommendedBlogs }) => {
  return (
    <BlogCardDeckStyles>
      <Container className="justify-content-center">
        <Row className="heading-info">
          <Col>
            <p className="blog-heading-kicker">Related resources</p>
            <h2>Check out our latest blogs</h2>
            <div className="subhead">
              Shopmonkey helps auto shops to work smarter, faster and grow their
              business with an all-in-one platform.
            </div>
            <CallToAction
              link="/blog"
              value="View All Blog Posts"
              variant="primary"
            />
          </Col>
        </Row>
        <Row>
          {recommendedBlogs.nodes.map(card => (
            <Col md={6} lg={4} key={card.id} className="card-wrapper">
              <BlogCard card={card} />
            </Col>
          ))}
        </Row>
      </Container>
    </BlogCardDeckStyles>
  );
};

export default BlogCardDeck;
